/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import { IAPIResponseStatus, IOptionValue, IStringObject } from '../interfaces';
import {
    IApprovalStage,
    IDocTypeCard,
    IDocTypeConfig,
    IDocTypeNumbering,
    IDocTypePopUp,
    IDocVersionObject,
    IDocumentTypeInfo,
    IGenerationTrigger,
} from '../interfaces/document-type';
import { IField } from '../interfaces/document';
import { getDocVersion, getUserFullName } from '../utils';
import { getRequest, postRequest, putRequest } from '../utils/api-request';
import { getRoles } from './roles';
import { convertEpochToDate } from '../utils/date';
import { IAttachmentConfig } from '../interfaces/attachment';
import { getGroupById } from './groups';

const createDocumentType = async (
    name: string,
    description: string,
    generationTrigger: 'ON_SUBMIT' | 'ON_CREATE',
    docTypeNumbering: IDocTypeNumbering[],
    config: IDocTypeConfig,
): Promise<
    | { apiStatus: IAPIResponseStatus; docTypeId: number; docTypeVersionId: number; response: any }
    | undefined
// eslint-disable-next-line consistent-return
> => {
    try {
        const res = await postRequest(`document-types/`, {
            name,
            description,
            documentNumberStrategy: {
                prefixes: docTypeNumbering,
                generationTrigger,
            },
            config,
        });
        if (res) {
            const { apiStatus, response } = res;
            return {
                apiStatus,
                docTypeId: response.documentTypeId,
                docTypeVersionId: response.id,
                response,
            };
        }
    } catch (err) {
        return undefined;
    }
};

const createDocumentTypeVersion = async (
    documentTypeId: number,
): Promise<
    { apiStatus: IAPIResponseStatus; docTypeVersionId: number; response: any } | undefined
// eslint-disable-next-line consistent-return
> => {
    try {
        const res = await postRequest(`document-types/${documentTypeId}/versions/`, {});
        if (res) {
            const { apiStatus, response } = res;
            return {
                apiStatus,
                docTypeVersionId: response.id,
                response,
            };
        }
    } catch (err) {
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getDocTypeVersion = async (
    docTypeId: number,
    docTypeVersionId: number,
): Promise<
    | {
        docInfo: IDocumentTypeInfo;
        fields: IField[];
        stages: IApprovalStage[];
        docNumbering: IDocTypeNumbering[];
        generationTrigger: IGenerationTrigger;
        attachments: IAttachmentConfig[];
        docTypeConfig: IDocTypeConfig;
    }
    | undefined
> => {
    try {
        const responseData = await getRequest(
            `document-types/${docTypeId}/versions/${docTypeVersionId}`,
        );
        const defaultInitialVersionValue: IDocVersionObject = {
            majorVersionNumber: 1,
            minorVersionNumber: 0,
        };
        if (responseData?.apiStatus === 'SUCCESS') {
            const { response } = responseData;
            const docInfo: IDocumentTypeInfo = {
                title: response?.documentType?.name,
                description: response?.documentType.description
                    ? response?.documentType?.description
                    : '',
                version: getDocVersion(response?.majorVersionNumber, response?.minorVersionNumber),
                status: response.state,
                isConfidential: response?.documentType.isConfidential
                    ? response?.documentType.isConfidential
                    : false,
                allowExport: response?.documentType?.config?.allowExport
                    ? response?.documentType?.config?.allowExport
                    : false,
                isDCO: response?.documentType?.config?.isDCO
                    ? response?.documentType?.config?.isDCO
                    : false,
                fullWidthTitleDesc: response?.documentType?.config?.fullWidthTitleDesc
                    ? response?.documentType?.config?.fullWidthTitleDesc
                    : false,
                singlePageDocCreation: response?.documentType?.config?.singlePageDocCreation
                    ? response?.documentType?.config?.singlePageDocCreation
                    : false,
                restrictCommentForPublished: response?.documentType?.config
                    ?.restrictCommentForPublished
                    ? response?.documentType?.config?.restrictCommentForPublished
                    : false,
                showCommentsAfterSubmitDate: response?.documentType?.config
                    ?.showCommentsAfterSubmitDate
                    ? response?.documentType?.config?.showCommentsAfterSubmitDate
                    : false,
                allowDCO: response?.documentType?.config?.allowDCO
                    ? response?.documentType?.config?.allowDCO
                    : false,
                allowRerelease: response?.documentType?.config?.allowRerelease
                    ? response?.documentType?.config?.allowRerelease
                    : false,
                allowObsolete: response?.documentType?.config?.allowObsolete
                    ? response?.documentType?.config?.allowObsolete
                    : false,
                allowReviseByAnyone: response?.documentType?.config?.allowReviseByAnyone
                    ? response?.documentType?.config?.allowReviseByAnyone
                    : false,
                allowViewingInProcessDocByAnyone: response?.documentType?.config?.allowViewingInProcessDocByAnyone
                    ? response?.documentType?.config?.allowViewingInProcessDocByAnyone
                    : false,
                allowCustomVersioning: response?.documentType?.config?.allowCustomVersioning
                    ? response?.documentType?.config?.allowCustomVersioning
                    : false,
                defaultInitialVersion: response?.documentType?.config?.defaultInitialVersion
                    ? response?.documentType?.config?.defaultInitialVersion
                    : defaultInitialVersionValue,
                blockRevise: response?.documentType?.config?.blockRevise
                    ? response?.documentType?.config?.blockRevise
                    : false,
                anyoneCanEditDraft: response?.documentType?.config?.anyoneCanEditDraft
                    ? response?.documentType?.config?.anyoneCanEditDraft
                    : false,
                enableApprovalDeadline: response?.documentType?.config?.enableApprovalDeadline
                    ? response?.documentType?.config?.enableApprovalDeadline
                    : false,
                defaultApprovalDeadline: response?.documentType?.config?.defaultApprovalDeadline
                    ? response?.documentType?.config?.defaultApprovalDeadline
                    : 5,
                hideEventTimeline: response?.documentType?.config?.hideEventTimeline
                    ? response?.documentType?.config?.hideEventTimeline
                    : false,
                storeAndShowActivity: response?.documentType?.config?.storeAndShowActivity
                    ? response?.documentType?.config?.storeAndShowActivity
                    : false,
                isContract: response?.documentType?.config?.isContract
                    ? response?.documentType?.config?.isContract
                    : false,
                allowTemplateGeneration: response?.documentTypeVersion?.documentType?.config?.allowTemplateGeneration
                    ? response.documentTypeVersion.documentType.config.allowTemplateGeneration
                    : false,
                detailsSectionTitle: response?.documentType?.config
                    ?.detailsSectionTitle
                    ? response?.documentType?.config?.detailsSectionTitle
                    : undefined,
                attachmentSectionTitle: response?.documentType?.config
                    ?.attachmentSectionTitle
                    ? response?.documentType?.config?.attachmentSectionTitle
                    : undefined,
                approversSectionTitle: response?.documentType?.config
                    ?.approversSectionTitle
                    ? response?.documentType?.config?.approversSectionTitle
                    : undefined,
                approverTimeStamp: response?.documentType?.config
                    ?.approverTimeStamp
                    ? response?.documentType?.config?.approverTimeStamp
                    : undefined,
                isFileType: response?.documentType?.config?.isFileType
                    ? response?.documentType?.config?.isFileType
                    : false,
                allowFileTypeAttachment: response?.documentType?.config?.allowFileTypeAttachment
                    ? response?.documentType?.config?.allowFileTypeAttachment
                    : false,
            };
            const fields: IField[] = [];
            const stages: IApprovalStage[] = [];
            const attachments: IAttachmentConfig[] = response?.attachmentConfiguration
                ? response?.attachmentConfiguration.attachments
                : [];
            const fieldsData = response?.fieldSet?.fields;
            const approvalWorkFlowData = response?.approvalWorkflowDefinition?.stages;
            const docTypeConfig: IDocTypeConfig = response?.documentType?.config
                ? response.documentType.config
                : { collectComment: false, collectCommentRejection: false, isConfidential: false, publishOnceApproved: true, allowExport: false, isDCO: false, allowDCO: false, allowReviseByAnyone: false, allowViewingInProcessDocByAnyone: false, allowCustomVersioning: false, defaultInitialVersion: defaultInitialVersionValue, enableApprovalDeadline: false, defaultApprovalDeadline: 5, isFileType: false };

            // TODO : get datatype mapping data

            if (fieldsData && fieldsData.length > 0) {
                for (const data of fieldsData) {
                    fields.push({
                        id: data.id,
                        name: data.name,
                        displayName: data.displayName,
                        step: data.step ? data.step : 0.01,
                        maxLength: data.maxLength ? data.maxLength : 0,
                        dataType: {
                            label: data.dataType.name,
                            uiControls: data.dataType.uiControls,
                            value: data.dataType.id,
                        },
                        uiControl: data.uiControl,
                        mandatory: data.isMandatory,
                        helperText: data.helperText ? data.helperText : '',
                        dynamicOptionValues: data.dynamicOptionValues
                            ? data.dynamicOptionValues
                            : null,
                        staticOptionValues: data.staticOptionValues
                            ? data.staticOptionValues
                            : null,
                        selectType: data.selectType ? data.selectType : null,
                        markupText: data.markupText ? data.markupText : '',
                        docTypeOptionValues: data.docTypeOptionValues ? data.docTypeOptionValues : null,
                        docTypeFieldsOptionValues: data.docTypeFieldsOptionValues ? data.docTypeFieldsOptionValues : null,
                        readOnly: data.readOnly ? data.readOnly : false,
                    });
                }
            }

            if (approvalWorkFlowData && approvalWorkFlowData.length > 0) {
                const i = 0;
                const rolesData = await getRoles();
                // TODO : Need to make it simple
                for (const s of approvalWorkFlowData) {
                    const roleList: IOptionValue[] = [];
                    if (s.scope.config?.roleList && rolesData) {
                        for (const roleId of s.scope.config?.roleList) {
                            const [role] = rolesData.roles.filter((r1) => r1.value === roleId);
                            if (role) {
                                roleList.push(role);
                            }
                        }
                    }
                    let group: IOptionValue | undefined;
                    if (
                        s.scope.config?.approvalType === 'ANYONE' ||
                        (s.scope.config?.scopeType === 'groupBased' &&
                            s.scope.config?.approvalType === 'INDIVIDUAL')
                    ) {
                        // eslint-disable-next-line no-await-in-loop
                        const groupData = await getGroupById(s.scope.config?.groupId);
                        group = groupData?.myGroups[0];
                    }
                    stages.push({
                        id: i + 1,
                        roleList,
                        dueDate:
                            s.reminders.length > 0
                                ? s.reminders.map((r: { value: number }) => r.value)
                                : [],
                        executionType: s.type,
                        approvalType: s.scope.config.approvalType,
                        groupCanEdit: s.scope.config.groupCanEdit,
                        customApproval: s.scope.config.customApproval,
                        ...(s.scope.config.approvalType === 'ANYONE' && {
                            approvalGroups: group,
                        }),
                        ...(s.scope.config?.scopeType === 'groupBased' &&
                            s.scope.config?.approvalType === 'INDIVIDUAL' && {
                            approvalGroups: group,
                        }),
                        ...(s.scope.config?.groupId && { groupId: s.scope.config?.groupId }),
                        depth: s.scope.config?.depth ? s.scope.config?.depth : 'L1',
                        scopeType: s.scope.type,
                        ...(s.scope.config?.multiApproval && {
                            multiApproval: s.scope.config.multiApproval,
                        }),
                        approvalStageTitle: s.scope.config.approvalStageTitle,
                        mandatory: s.scope.config.mandatory,
                    });
                }
            }

            const docNumbering: IDocTypeNumbering[] =
                response?.documentType?.documentNumberStrategy?.prefixes;
            const generationTrigger = response?.documentType?.documentNumberStrategy
                ?.generationTrigger
                ? response?.documentType?.documentNumberStrategy?.generationTrigger
                : 'ON_SUBMIT';
            return {
                docInfo,
                fields,
                stages,
                docNumbering,
                generationTrigger,
                attachments,
                docTypeConfig,
            };
        }
        return undefined;
    } catch (err) {
        // console.error(err);
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const updateDocTypeVersion = async (
    docTypeId: number,
    docTypeVersionId: number,
    fieldsData: IField[],
    stagesData: IApprovalStage[],
    attachments: IAttachmentConfig[],
): Promise<IAPIResponseStatus | undefined> => {
    try {
        const fields: any = [];
        const stages: any = [];
        for (const data of fieldsData) {
            let staticOptionValues: any;
            if (data.staticOptionValues?.data && data.staticOptionValues?.data.length > 0) {
                staticOptionValues = data.staticOptionValues;
            }
            let dynamicOptionValues: any;
            if (data.dynamicOptionValues) {
                dynamicOptionValues = data.dynamicOptionValues;
            }
            const fieldData = {
                id: data.id,
                name: data.name,
                displayName: data.displayName,
                dataType: {
                    name: data.dataType.label,
                    uiControls: data.dataType.uiControls,
                    id: data.dataType.value,
                },
                maxLength: data.maxLength ? data.maxLength : 0,
                helperText: data.helperText,
                uiControl: data.uiControl,
                step: data.step ? data.step : 0.01,
                isMandatory: data.mandatory,
                staticOptionValues,
                dynamicOptionValues,
                selectType: data.selectType ? data.selectType : null,
                markupText: data.markupText,
                docTypeOptionValues: data.docTypeOptionValues ? data.docTypeOptionValues : null,
                docTypeFieldsOptionValues: data.docTypeFieldsOptionValues ? data.docTypeFieldsOptionValues : null,
                readOnly: data.readOnly ? data.readOnly : false,
            };

            fields.push(fieldData);
        }

        for (const stage of stagesData) {
            const config: { [key: string]: any } = {};
            if (stage.scopeType === 'roleBased') {
                config.approvalType = stage.approvalType;
                config.customApproval = stage.customApproval;

                if (stage.approvalType === 'INDIVIDUAL') {
                    config.multiApproval = stage.multiApproval;
                    config.roleList = stage.roleList.map((r) => r.value);
                } else if (stage.approvalType === 'ANYONE' && stage.approvalGroups) {
                    config.groupId = stage.approvalGroups.value;
                }
            }
            if (stage.scopeType === 'groupBased') {
                // stage: {"id":1,"roleList":[],"dueDate":[],"executionType":"SEQUENTIAL","approvalType":"INDIVIDUAL","groupCanEdit":false,"customApproval":false,"depth":"L1","scopeType":"groupBased","approvalGroups":{"value":2,"label":"DCON"}}
                config.scopeType = stage.scopeType;
                config.approvalType = stage.approvalType;
                if (stage.approvalGroups) {
                    config.groupId = stage.approvalGroups.value;
                }
                config.customApproval = stage.customApproval;

                // if (stage.approvalType === 'INDIVIDUAL') {
                //     // config.multiApproval = stage.multiApproval;
                //     // config.roleList = stage.roleList.map((r) => r.value);
                // } else if (stage.approvalType === 'ANYONE' && stage.approvalGroups) {
                //     config.groupId = stage.approvalGroups.value;
                // }
            }
            if (stage.scopeType === 'managementChain') {
                config.depth = stage.depth;
            }
            config.groupCanEdit = stage.groupCanEdit;

            config.approvalStageTitle = stage.approvalStageTitle;
            config.mandatory = stage.mandatory;
            const stageData = {
                type: stage.executionType,
                scope: {
                    type: stage.scopeType,
                    config,
                },
                reminders: stage.dueDate.map((d) => ({ value: d })),
            };
            stages.push(stageData);
        }

        const response = await putRequest(
            `document-types/${docTypeId}/versions/${docTypeVersionId}`,
            {
                fieldSet: {
                    fields,
                },
                approvalWorkflowDefinition: {
                    stages,
                },
                attachmentConfiguration: {
                    attachments,
                },
            },
        );
        return response?.apiStatus;
    } catch (err) {
        return undefined;
    }
};

const updateDocType = async (
    docTypeId: number,
    docTypeNumbering: IDocTypeNumbering[],
    generationTrigger: 'ON_SUBMIT' | 'ON_CREATE',
    config: IDocTypeConfig,
    name?: string,
    description?: string,
): Promise<IAPIResponseStatus | undefined> => {
    try {
        const body: any = {
            documentNumberStrategy: {
                prefixes: docTypeNumbering,
                generationTrigger,
            },
            config,
        };
        if (name) {
            body.name = name;
        }
        if (description) {
            body.description = description;
        }
        const response = await putRequest(`document-types/${docTypeId}/`, body);
        return response?.apiStatus;
    } catch (err) {
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getAllDocumentTypes = async (): Promise<
    { apiStatus: IAPIResponseStatus; docTypeCard: IDocTypeCard[] } | undefined
> => {
    try {
        const res = await getRequest(`document-types?draft=true`);
        const docTypeCard: IDocTypeCard[] = [];
        if (res?.apiStatus === 'SUCCESS') {
            const docTypeData = res.response;
            for (const docType of docTypeData) {
                const activeVersion = docType.draftDocumentTypeVersionId
                    ? docType.draftDocumentTypeVersionId
                    : docType.lastPublishedDocumentTypeVersionId;
                const docTypeVersion = docType.draftDocumentTypeVersionId
                    ? docType.draftDocumentTypeVersion
                    : docType.lastPublishedDocumentTypeVersion;
                docTypeCard.push({
                    id: docType.id,
                    activeVersion,
                    createdDate: new Date(convertEpochToDate(docType.createdAt)),
                    lastUpdateDate: new Date(convertEpochToDate(docType.updatedAt)),
                    status: docTypeVersion.state,
                    name: docType.name,
                    config: docType.config,
                    totalDocuments: docType.documentCount,
                    documentNumberStrategy: docType.documentNumberStrategy,
                    version: getDocVersion(
                        docTypeVersion.majorVersionNumber,
                        docTypeVersion.minorVersionNumber,
                    ),
                    updatedBy: getUserFullName(
                        docTypeVersion.updatedBy.firstName,
                        docTypeVersion.updatedBy.lastName,
                    ),
                });
            }

            return { apiStatus: res.apiStatus, docTypeCard };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getPublishedDocumentTypes = async (
    query = '',
): Promise<{ apiStatus: IAPIResponseStatus; docTypeCard: IDocTypePopUp[] } | undefined> => {
    try {
        const res = await getRequest(`document-types?draft=false&name=${query}`);
        const docTypeData: IDocTypePopUp[] = [];
        if (res?.apiStatus === 'SUCCESS') {
            const docTypeData1 = res.response;
            for (const docType of docTypeData1) {
                // TODO: better way to fetch code
                const [staticPrefix] = docType.documentNumberStrategy.prefixes.filter(
                    (c: { type: string }) => c.type === 'static',
                );
                let code = '';
                if (staticPrefix) {
                    code = staticPrefix.value;
                }
                const fields: { name: string; displayName: string }[] = [];
                const fieldsList = docType.lastPublishedDocumentTypeVersion.fieldSet.fields;
                if (fieldsList) {
                    for (const field of fieldsList) {
                        fields.push({ displayName: field.displayName, name: field.name });
                    }
                }
                const isDCOvalue = docType.config?.isDCO ?? false;
                const config = { isDCO: isDCOvalue };
                docTypeData.push({
                    id: docType.id,
                    description: docType.description,
                    name: docType.name,
                    updatedAt: new Date(convertEpochToDate(docType.updatedAt)),
                    code,
                    fields,
                    config,
                    isAllowedForDCO: docType.config.allowDCO ? docType.config.allowDCO : false,
                    isDCO: docType.config.isDCO ? docType.config.isDCO : false,
                });
            }
            return { apiStatus: res.apiStatus, docTypeCard: docTypeData };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getDocTypes = async (
    queryParams: IStringObject,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        docTypeCard: IDocTypePopUp[];
    }
    | undefined
> => {
    let query = '';
    // eslint-disable-next-line no-prototype-builtins
    if (queryParams.hasOwnProperty('inputStr')) {
        query = `draft=false&name=${queryParams.inputStr}&sortBy=${queryParams.sortBy}`;
    } else {
        query = `draft=false&sortBy=${queryParams.sortBy}`;
    }
    try {
        const res = await getRequest(`document-types?${query}`);
        const docTypeData: IDocTypePopUp[] = [];
        if (res?.apiStatus === 'SUCCESS') {
            const docTypeData1 = res.response;
            for (const docType of docTypeData1) {
                // TODO: better way to fetch code
                const [staticPrefix] = docType.documentNumberStrategy.prefixes.filter(
                    (c: { type: string }) => c.type === 'static',
                );
                let code = '';
                if (staticPrefix) {
                    code = staticPrefix.value;
                }
                const fields: { name: string; displayName: string }[] = [];
                const fieldsList = docType.lastPublishedDocumentTypeVersion.fieldSet.fields;
                if (fieldsList) {
                    for (const field of fieldsList) {
                        fields.push({ displayName: field.displayName, name: field.name });
                    }
                }
                const InactiveDocumentTypeId = process.env.REACT_APP_INACTIVE_DOCUMENT_TYPE_ID;
                if (docType.id.toString() !== InactiveDocumentTypeId) {
                    docTypeData.push({
                        id: docType.id,
                        description: docType.description,
                        name: docType.name,
                        updatedAt: new Date(convertEpochToDate(docType.updatedAt)),
                        code,
                        fields,
                        isAllowedForDCO: docType.config.allowDCO ? docType.config.allowDCO : false,
                        isDCO: docType.config.isDCO ? docType.config.isDCO : false
                    });
                }
            }
            return { apiStatus: res.apiStatus, docTypeCard: docTypeData };
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
};

const getDocTypeInfoNotes = async (
    docTypeId: number,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        docTypeInfoNotesMarkup: string;
    }
    | undefined
> => {
    try {
        const res = await getRequest(`document-types/${docTypeId}/info-notes`);
        if (res?.apiStatus === 'SUCCESS') {
            return { apiStatus: res.apiStatus, docTypeInfoNotesMarkup: res.response?.infoNote };
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
};


const publishDocumentType = async (
    docTypeId: number,
    docTypeVersionId: number,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const response = await putRequest(
            `document-types/${docTypeId}/versions/${docTypeVersionId}/publish`,
            {},
        );
        if (response) {
            return { apiStatus: response.apiStatus };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

// const getDocumentTypeVersions = async (
//     documentTypeId: number,
// ): Promise<{ apiStatus: IAPIResponseStatus; documentTypeVersions: IOptionValue[] } | undefined> => {
//     try {
//         const documentTypeVersions: IOptionValue[] = [
//             {
//                 label: '1.0',
//                 value: '1.0',
//             },
//             {
//                 label: '2.0',
//                 value: '2.0',
//             },
//             {
//                 label: '3.0',
//                 value: '3.0',
//             },
//         ];
//         return { apiStatus: 'SUCCESS', documentTypeVersions };
//     } catch (err) {
//         console.log(err);
//         return undefined;
//     }
// };

export {
    createDocumentType,
    createDocumentTypeVersion,
    getDocTypeVersion,
    updateDocTypeVersion,
    updateDocType,
    getAllDocumentTypes,
    publishDocumentType,
    getPublishedDocumentTypes,
    getDocTypes,
    getDocTypeInfoNotes
    // getDocumentTypeVersions,
};
